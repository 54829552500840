<template>
  <section id="section-careers-available" class="bg-monochrome-grey-super-light-grey">
    <div class="nstra-container">
      <div class="section-header" v-scrollanimation="'up'">
        <h1 class="section-title">
          Available positions that
          <span class="text-primary">we need(s).</span>
        </h1>
      </div>
      <div class="section-body">
        <div class="accordion" role="tablist" v-scrollanimation="'up'">
          <div :class="['accordion-item', parent.visible ? 'accordion-show' : '']" v-for="(parent, index) in groupJob" :key="index">
            <div class="accordion-header" @click="parent.visible = !parent.visible">
              <div class="accordion-icon">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" width="14" v-if="parent.visible">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 11l7-7 7 7M5 19l7-7 7 7" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" width="14" v-else>
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 13l-7 7-7-7m14-8l-7 7-7-7" />
                </svg>
              </div>
              <h5 class="accordion-title">({{ parent.data.length }}) Available Positions - {{ parent.time }}</h5>
            </div>
            <b-collapse id="collapse-4" v-model="parent.visible">
              <div class="accordion-body">
                <div class="list list-careers-available" style="width: 100%">
                  <div class="list-item" v-for="(item, index2) in parent.data" :key="`${index}${index2}`">
                    <b-row class="align-items-center">
                      <b-col lg="3" class="mb-20px mb-lg-0px">
                        <h5 class="career-title">{{ item.title }}</h5>
                        <span class="career-time">{{ item.workTime }}</span>
                      </b-col>
                      <b-col lg="6" class="mb-20px mb-lg-0px">
                        <p class="career-text">
                          {{ item.description }}
                        </p>
                      </b-col>
                      <b-col lg="3" class="text-lg-center">
                        <router-link :to="`/career/${item.id}`">
                          <button class="btn btn-outline-primary btn-dashed">
                            View Job
                          </button>
                        </router-link>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from "vue";
import { BRow, BCol, VBToggle, BCollapse } from "bootstrap-vue";
import JobService from "@/services/JobService";

Vue.directive("b-toggle", VBToggle);

export default {
  name: "Career",
  components: {
    BRow,
    BCol,
    BCollapse,
  },
  data: function() {
    return {
      groupJob: [],
      jobList: [],
      pagination: {
        currentPage: 1,
        totalPages: 1,
        totalElements: 1,
      },
      visible: true,
    };
  },
  methods: {
    getJobList() {
      JobService.getJobList().then((res) => {
        this.jobList = res.data;
        this.pagination = {
          currentPage: res.currentPage,
          totalPages: res.totalPages,
          totalElements: res.totalElements,
        };

        const groupByKey = function(array, key) {
          return array.reduce((hash, obj) => {
            if (obj[key] === undefined) return hash;
            return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) });
          }, {});
        };

        const group = groupByKey(this.jobList, "workTime");

        if (Object.keys(group).length > 0) {
          Object.keys(group).forEach((item) => {
            this.groupJob.push({
              data: group[item],
              time: item,
              visible: true
            })
          });
        }
      });
    },
  },
  mounted() {
    this.getJobList();
  },
};
</script>
